import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../../components/Core";
import PageWrapper from "../../components/PageWrapper";
import CTA from "../../sections/common/CTA";
import CommingUp from "../../sections/common/CommingUp";
import imgS21 from "../../assets/image/jpg/lisa/corporate-design/Bildmarke_Mockup.png";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Section hero>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">BRANDING</Text>
                  <Title className="my-4">
                    Celeris Therapeutics <br /> pharmaceutical tech company.
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                  Logo design for an austrian pharma-tech company, specialized in medical AI.
                  </Text>
                </Box>
              </Col>
            </Row>

            <Box pt={["4rem", null, null, "6.25rem"]}>
              <img src={imgS21} alt="" className="img-fluid w-100" />
            </Box>
          </Container>
        </Section>

        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Goal</Text>
                <Title variant="cardBig" className="mt-3">
                  Modern and technical logo redesign to show inovation and skill.
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Date</Text>
                <Title variant="cardBig" className="mt-3">
                   Jun, 2021
                </Title>
              </Col>
              <Col lg="4" className="d-flex justify-content-lg-end">
                <Button arrowRight color="#fff">learn more</Button>
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="mt-lg-5 pb-0">
          <Container>
            <Row>
              
              
            </Row>
          </Container>
        </Section>

        <Section bg="##FFFFFF" className="pt-6">
          <div className="mt-lg-4">
            <CommingUp name="Muchas GRAZias" link="/project/muchasgracias" />
          </div>  
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
